"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.iso2CountryOverrideMap = exports.getCurrencyExchangeRateByLocale = exports.getUserPreferredLocale = exports.defaultCurrencyRate = exports.defaultCurrencyCode = exports.defaultLocale = exports.defaultLocaleCountryCode = exports.MAX_AGE = exports.USER_PREFERENCES_COOKIE = exports.PREFERRED_LOCALE_COOKIE = void 0;
// import { LocaleCurrency } from '../types/Shared/Locale'
const constants_1 = require("./cookies/constants");
var constants_2 = require("./cookies/constants");
Object.defineProperty(exports, "PREFERRED_LOCALE_COOKIE", { enumerable: true, get: function () { return constants_2.PREFERRED_LOCALE_COOKIE; } });
Object.defineProperty(exports, "USER_PREFERENCES_COOKIE", { enumerable: true, get: function () { return constants_2.USER_PREFERENCES_COOKIE; } });
Object.defineProperty(exports, "MAX_AGE", { enumerable: true, get: function () { return constants_2.MAX_AGE; } });
// Used for Locale Preferences both BE & FE
exports.defaultLocaleCountryCode = 'US';
exports.defaultLocale = 'en-US'; // en-US
exports.defaultCurrencyCode = 'USD';
exports.defaultCurrencyRate = {
    countryCode: 'US',
    currencyCode: exports.defaultCurrencyCode,
    displayName: 'United States',
    exchangeRate: 1,
    locale: 'en-US', // Can be lowerCase, but docs show en-CA
};
const getUserPreferredLocale = (req) => {
    if (req && req.cookies) {
        return req.cookies[constants_1.PREFERRED_LOCALE_COOKIE] || exports.defaultLocale;
    }
    return exports.defaultLocale;
};
exports.getUserPreferredLocale = getUserPreferredLocale;
// Preferred Locale Cookie
const getCurrencyExchangeRateByLocale = (data, locale) => {
    if (data[locale]) {
        return data[locale];
    }
    return exports.defaultCurrencyRate;
};
exports.getCurrencyExchangeRateByLocale = getCurrencyExchangeRateByLocale;
// map incoming akamai iso2 countries to a different value e.g. unify all
// countries using the euro
//
// This map is here because we only have one item for euros in the currency
// selector dropdown in the site UI. This allows us to map incoming akamai
// country headers for countries that are in the EU to the euros selection in
// that dropdown.
// technically the EU isn't a country but we treat it that way for currency
// conversion purposes.
const EU_COUNTRY_CODE = 'EU';
const europeanUnionCountries = {
    AT: EU_COUNTRY_CODE,
    BE: EU_COUNTRY_CODE,
    BG: EU_COUNTRY_CODE,
    HR: EU_COUNTRY_CODE,
    CY: EU_COUNTRY_CODE,
    CZ: EU_COUNTRY_CODE,
    DK: EU_COUNTRY_CODE,
    EE: EU_COUNTRY_CODE,
    FI: EU_COUNTRY_CODE,
    FR: EU_COUNTRY_CODE,
    GR: EU_COUNTRY_CODE,
    DE: EU_COUNTRY_CODE,
    HU: EU_COUNTRY_CODE,
    IE: EU_COUNTRY_CODE,
    IT: EU_COUNTRY_CODE,
    LV: EU_COUNTRY_CODE,
    LT: EU_COUNTRY_CODE,
    LU: EU_COUNTRY_CODE,
    MT: EU_COUNTRY_CODE,
    NL: EU_COUNTRY_CODE,
    PL: EU_COUNTRY_CODE,
    PT: EU_COUNTRY_CODE,
    RO: EU_COUNTRY_CODE,
    SK: EU_COUNTRY_CODE,
    SI: EU_COUNTRY_CODE,
    ES: EU_COUNTRY_CODE,
    SE: EU_COUNTRY_CODE,
};
exports.iso2CountryOverrideMap = Object.assign({}, europeanUnionCountries);
