"use strict";
// Hard Coded dump from SQL
// We are going to use a JSON file ( not an API call )
Object.defineProperty(exports, "__esModule", { value: true });
exports.ISO2_GEO_ZONES = void 0;
/**
 * Use the following tinker script to generate the zones:
 * $rows = DB::table('geo_shipping_countries')->get()->keyBy('iso2')->map(fn ($row) => $row->zone)->toArray();
 * echo json_encode($rows, JSON_PRETTY_PRINT);
 */
// ISO2 → ZONE
exports.ISO2_GEO_ZONES = {
    CA: 'AM1A',
    MX: 'AM1B',
    AG: 'AM2K',
    AI: 'AM2K',
    AW: 'AM2K',
    BB: 'AM2K',
    BM: 'AM2K',
    BS: 'AM2K',
    CU: 'AM2K',
    CW: 'AM2K',
    DM: 'AM2K',
    DO: 'AM2K',
    GD: 'AM2K',
    GP: 'AM2K',
    HT: 'AM2K',
    JM: 'AM2K',
    KN: 'AM2K',
    KY: 'AM2K',
    LC: 'AM2K',
    MF: 'AM2K',
    MQ: 'AM2K',
    MS: 'AM2K',
    SX: 'AM2K',
    TC: 'AM2K',
    TT: 'AM2K',
    VC: 'AM2K',
    VG: 'AM2K',
    XB: 'AM2K',
    XE: 'AM2K',
    XM: 'AM2K',
    XN: 'AM2K',
    XY: 'AM2K',
    BZ: 'AM2L',
    CR: 'AM2L',
    GT: 'AM2L',
    HN: 'AM2L',
    NI: 'AM2L',
    PA: 'AM2L',
    SV: 'AM2L',
    AR: 'AM3L',
    BO: 'AM3L',
    BR: 'AM3L',
    CL: 'AM3L',
    CO: 'AM3L',
    EC: 'AM3L',
    GF: 'AM3L',
    GY: 'AM3L',
    PE: 'AM3L',
    PY: 'AM3L',
    SR: 'AM3L',
    UY: 'AM3L',
    VE: 'AM3L',
    JP: 'AP1F',
    KR: 'AP1F',
    CN: 'AP1G',
    HK: 'AP1H',
    SG: 'AP1H',
    TW: 'AP1H',
    MO: 'AP1I',
    MY: 'AP1I',
    TH: 'AP1I',
    AU: 'AP2I',
    BD: 'AP2I',
    ID: 'AP2I',
    IN: 'AP2I',
    LK: 'AP2I',
    NZ: 'AP2I',
    PH: 'AP2I',
    VN: 'AP2I',
    BE: 'EU1C',
    DE: 'EU1C',
    ES: 'EU1C',
    FR: 'EU1C',
    IE: 'EU1C',
    IT: 'EU1C',
    LU: 'EU1C',
    MC: 'EU1C',
    NL: 'EU1C',
    PT: 'EU1C',
    SM: 'EU1C',
    VA: 'EU1C',
    AT: 'EU1D',
    GB: 'EU2C',
    AD: 'EU2D',
    CY: 'EU2D',
    DK: 'EU2D',
    FI: 'EU2D',
    GR: 'EU2D',
    MT: 'EU2D',
    SE: 'EU2D',
    BG: 'EU2E',
    CZ: 'EU2E',
    EE: 'EU2E',
    HR: 'EU2E',
    HU: 'EU2E',
    LT: 'EU2E',
    LV: 'EU2E',
    PL: 'EU2E',
    RO: 'EU2E',
    SI: 'EU2E',
    SK: 'EU2E',
    CH: 'EU3D',
    FO: 'EU3D',
    GG: 'EU3D',
    GL: 'EU3D',
    IC: 'EU3D',
    IS: 'EU3D',
    JE: 'EU3D',
    LI: 'EU3D',
    NO: 'EU3D',
    AL: 'EU3E',
    BA: 'EU3E',
    GI: 'EU3E',
    ME: 'EU3E',
    MK: 'EU3E',
    RS: 'EU3E',
    IL: 'EU3M',
    TR: 'EU3M',
    AE: 'MA1M',
    BH: 'MA1M',
    KW: 'MA1M',
    OM: 'MA1M',
    QA: 'MA1M',
    SA: 'MA1M',
    ZA: 'MA1N',
    AF: 'ROWE',
    AZ: 'ROWE',
    BY: 'ROWE',
    GE: 'ROWE',
    IQ: 'ROWE',
    IR: 'ROWE',
    KG: 'ROWE',
    KV: 'ROWE',
    KZ: 'ROWE',
    MD: 'ROWE',
    RU: 'ROWE',
    TJ: 'ROWE',
    TM: 'ROWE',
    UA: 'ROWE',
    UZ: 'ROWE',
    BN: 'ROWJ',
    BT: 'ROWJ',
    CK: 'ROWJ',
    FJ: 'ROWJ',
    KH: 'ROWJ',
    KI: 'ROWJ',
    LA: 'ROWJ',
    MM: 'ROWJ',
    MN: 'ROWJ',
    MV: 'ROWJ',
    NC: 'ROWJ',
    NP: 'ROWJ',
    NR: 'ROWJ',
    NU: 'ROWJ',
    PF: 'ROWJ',
    PG: 'ROWJ',
    SB: 'ROWJ',
    TL: 'ROWJ',
    TO: 'ROWJ',
    TV: 'ROWJ',
    VU: 'ROWJ',
    WS: 'ROWJ',
    FK: 'ROWL',
    AM: 'ROWM',
    EG: 'ROWM',
    JO: 'ROWM',
    LB: 'ROWM',
    PK: 'ROWM',
    SY: 'ROWM',
    YE: 'ROWM',
    AO: 'ROWN',
    BF: 'ROWN',
    BI: 'ROWN',
    BJ: 'ROWN',
    BW: 'ROWN',
    CD: 'ROWN',
    CF: 'ROWN',
    CG: 'ROWN',
    CI: 'ROWN',
    CM: 'ROWN',
    CV: 'ROWN',
    DJ: 'ROWN',
    DZ: 'ROWN',
    ER: 'ROWN',
    ET: 'ROWN',
    GA: 'ROWN',
    GH: 'ROWN',
    GM: 'ROWN',
    GN: 'ROWN',
    GQ: 'ROWN',
    GW: 'ROWN',
    KE: 'ROWN',
    KM: 'ROWN',
    LR: 'ROWN',
    LS: 'ROWN',
    LY: 'ROWN',
    MA: 'ROWN',
    MG: 'ROWN',
    ML: 'ROWN',
    MR: 'ROWN',
    MU: 'ROWN',
    MW: 'ROWN',
    MZ: 'ROWN',
    NA: 'ROWN',
    NE: 'ROWN',
    NG: 'ROWN',
    RE: 'ROWN',
    RW: 'ROWN',
    SC: 'ROWN',
    SD: 'ROWN',
    SH: 'ROWN',
    SL: 'ROWN',
    SN: 'ROWN',
    SO: 'ROWN',
    SS: 'ROWN',
    ST: 'ROWN',
    SZ: 'ROWN',
    TD: 'ROWN',
    TG: 'ROWN',
    TN: 'ROWN',
    TZ: 'ROWN',
    UG: 'ROWN',
    YT: 'ROWN',
    ZM: 'ROWN',
    ZW: 'ROWN',
    AS: 'US',
    GU: 'US',
    MP: 'US',
    PR: 'US',
    UM: 'US',
    US: 'US',
    VI: 'US',
};
exports.default = exports.ISO2_GEO_ZONES;
