"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// DON"T REMOVE - USED Server Side - Exported in 'lib/helpers'
const capitalizeString = (str) => `${str}`.toLowerCase().replace(/^(.)|[\s|\-|'|`]+(.)/g, ($1) => $1.toUpperCase()) || '';
// slugify('This WorKing') => 'this-working'
const slugify = (str) => String(str || '')
    .replace(/[^a-z0-9]/gi, '-')
    .toLowerCase()
    .replace(/-{2,}/g, '-') // Remove Multiple '-': 'this-is---a-link' => 'this-is-a-link'
    .replace(/^-/, '') // Remove Starting `-`
    .replace(/-$/, '');
// Remove Trailing '-'
const slugifyTitle = (str) => slugify(str)
    .split('-')
    .filter(Boolean)
    .map(capitalizeString)
    .join('-');
// Expects a object => { "Abstract": 897, "Fine Art": 638, "Abstract Expressionism": 450, ... }
// Will return a new Object => { abstract: "Abstract", "fine-art", "Fine Art", "abstract-expressionism": "Abstract Expressionism", ... }
const buildSlugifyObject = (obj) => Object.keys(obj).reduce((results, key) => (Object.assign(Object.assign({}, results), { [slugify(key)]: key })), {});
const buildObject = (obj) => Object.keys(obj).reduce((results, key) => (Object.assign(Object.assign({}, results), { [key]: key })), {});
// Expects a object => { facets: { "Abstract": 897, "Fine Art": 638, "Abstract Expressionism": 450, ... } }
// Will return a new Object => { facets: { abstract: "Abstract", "fine-art", "Fine Art", "abstract-expressionism": "Abstract Expressionism", ... } }
const buildSlugifyDictionary = (obj) => Object.entries(obj).reduce((results, [key, value]) => key.search(/curated_status/) > -1 // Skip these values - do NOT slugify
    ? Object.assign(Object.assign({}, results), { [key]: buildObject(value) }) : Object.assign(Object.assign({}, results), { [key]: buildSlugifyObject(value) }), {});
module.exports = {
    buildSlugifyDictionary,
    buildSlugifyObject,
    capitalizeString,
    slugify,
    slugifyTitle,
};
