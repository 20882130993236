"use strict";
//@ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.displayEllipticalString = exports.updateCriticalImagePath = exports.buildSchemaObjects = exports.isEmptyObject = exports.getDataLayerItemVariant = exports.formatPhoneNumber = exports.uniqueId = exports.updateList = exports.urlQuery = exports.arrayQuery = exports.stringQuery = exports.slugifyPath = exports.stringToQueryObject = exports.objectToQueryString = exports.sortObject = exports.filterObject = exports.splitAndCapitalizeString = exports.stringCleanup = exports.capitalizeString = void 0;
const slugify_1 = require("lib/slugify");
var slugify_2 = require("lib/slugify");
Object.defineProperty(exports, "capitalizeString", { enumerable: true, get: function () { return slugify_2.capitalizeString; } });
const { APP_ENV } = process.env;
const DOMAIN = APP_ENV === 'local' || APP_ENV === 'qa'
    ? 'https://www.qa.saatchiart.com'
    : 'https://www.saatchiart.com';
const stringCleanup = (value) => value.replace(/^\s+|\s+$|\s+(?=\s)/g, '');
exports.stringCleanup = stringCleanup;
const splitAndCapitalizeString = (str) => {
    const cleanString = str &&
        str
            .split('-')
            .map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
            .join(' ');
    return cleanString;
};
exports.splitAndCapitalizeString = splitAndCapitalizeString;
const filterObject = (obj, filters) => {
    const updatedObject = {};
    Object.keys(Object.assign({}, obj))
        .filter((key) => !filters.includes(key))
        .forEach((key) => (updatedObject[key] = obj[key]));
    return updatedObject;
};
exports.filterObject = filterObject;
// Lets Alphabetize so we always have same results ( specifically for TESTS )
const sortObject = (obj) => Object.keys(obj)
    .sort()
    .reduce((data, key) => (Object.assign(Object.assign({}, data), { [key]: obj[key] })), {});
exports.sortObject = sortObject;
const objectToQueryString = (obj) => {
    /*
      Objects can be returned in two manners STRING | ARRAY of value
      Ex: { category: 'painting', styles: [ 'fine-art ', 'modern', 'figurative'] }
      First, we'll map the keys to assess each value and process it.
      */
    return (Object.keys(obj)
        .map((key) => {
        // Get the value
        const val = obj[key];
        /* Test to see if the value is an array. ex: [ 'small', 'medium' ]
    If it is, map through the array and create a key/value URI string for each item,
    connected by an equal sign '=' and joined with an ampersand.
    ex: size[]=small&size[]=medium
    */
        if (Array.isArray(val)) {
            return val.map((item) => `${key}=${encodeURIComponent(item)}`).join('&'); // Don't allow keys to pass through with an enpty string as the value
        }
        else if (val !== '') {
            // If it's not an array, just create the normal string for the URI.
            return `${key}=${encodeURIComponent(val) || ''}`;
        }
        else {
            return null;
        }
    }) // Make sure we don't map over any null or undefined keys
        .filter(Boolean)
        /* Join up all the key/value substrings into a full URI string. Up to this point, our URI is just
    an array of substrings.
    ex: [ 'materials[]=canvas&materials[]=b-w-paper','size[]=small&size[]=medium','hitsPerPage=100' ]
    Joining will turn this array into a joined string.
    ex: materials[]=canvas&materials[]=b-w-paper&size[]=small&size[]=medium&hitsPerPage=100
    */
        .join('&'));
};
exports.objectToQueryString = objectToQueryString;
const stringToQueryObject = (str = '') => {
    const queryObject = {};
    if (str) {
        str
            /* Clean the string of its question mark and split on the ampersands,
            we just want substrings of key/value pairs separated by equal signs '='
            ex: 'materials[]=canvas' 'materials[]=b-w-paper' 'size[]=small' 'size[]=medium' 'hitsPerPage=100'
          */
            .replace(/^\?/g, '')
            .split('&')
            .forEach((item) => {
            // We'll iterate through each key/value pair and split them on the equal sign '='
            const [key, val] = item.split('=');
            if (Object.prototype.hasOwnProperty.call(queryObject, key)) {
                /* If the queryObject is seeing a key that has already passed through once, it means that the key has multiple values. So we'll push those values into an array.
              ex: { 'size[]': [ 'small', 'medium' ] }
              */
                queryObject[key] = [queryObject[key], val];
            }
            else {
                /* By default, we'll just drop any simple key/value pair into the object.
              ex: { hitsPerPage: '100' }
              */
                queryObject[key] = val;
            }
            /* The final queryObject for our example string will look like this:
          { 'materials[]': [ 'canvas', 'b-w-paper' ],
            'size[]': [ 'small', 'medium' ],
            hitsPerPage: '100' } }
          */
        });
    }
    return queryObject;
};
exports.stringToQueryObject = stringToQueryObject;
// urlParams => ['Photography', 'Fine-Art', 'Portrait']
// Results `/photography/fine-art/portrait`
const slugifyPath = (urlParams) => `/${urlParams.map(slugify_1.slugify).join('/')}`;
exports.slugifyPath = slugifyPath;
// Params ( page, '25' )
// Result => `page=25`
const stringQuery = (key, value) => {
    if (!key || !value) {
        return '';
    }
    // List of Facets we want to slugify => Ex: Color
    if (key === 'color') {
        return `${key}=${(0, slugify_1.slugify)(value)}`;
    }
    return `${key}=${encodeURIComponent(value)}`;
};
exports.stringQuery = stringQuery;
// Params ( styles, ['Fine Art', 'Figurative', 'Modern' ])
// Result => `styles[]=fine-art&styles[]=figurative&styles[]=modern`
const arrayQuery = (key, values) => {
    if (!values.length) {
        return '';
    }
    // If only 1 item NO BRACKETS => `styles=fine-art`
    return values.map((value) => `${key}${values.length > 1 ? '[]' : ''}=${(0, slugify_1.slugify)(value)}`).join('&');
};
exports.arrayQuery = arrayQuery;
// Params ({ page: 1, styles: ['Fine Art', 'Figurative'], hitsPerPage: 25 })
// Result => `?page=1&styles[]=fine-art&styles[]=figurative&hitsPerPage=25`
const urlQuery = (obj) => {
    const sorted = (0, exports.sortObject)(obj);
    const query = Object.entries(sorted)
        .filter(([key, value]) => {
        // Remove EMPTY and FALSE values => '', false, 0
        if (!value) {
            return false;
        }
        return true;
    })
        .map(([key, value]) => Array.isArray(value)
        ? (0, exports.arrayQuery)(key, value.filter(Boolean))
        : (0, exports.stringQuery)(String(key), String(value)))
        .filter(Boolean)
        .join('&');
    return query.length ? `?${query}` : ``;
};
exports.urlQuery = urlQuery;
// We want to create a list/ARRAY of strings and want to be able to add/remove values
const updateList = (list, newValue) => {
    // Make sure it is an ARRAY or turn it into a ARRAY
    const results = !list || !Array.isArray(list) ? [list] : list;
    // Does value exist
    const doesExist = results.includes(newValue);
    return doesExist
        ? [...results.filter((attr) => attr !== newValue)] // REMOVE value
        : [...results, newValue]; // ADD value
};
exports.updateList = updateList;
// this is 9 or 10 characters long
const uniqueId = () => Math.random()
    .toString(36)
    .substring(2, 12);
exports.uniqueId = uniqueId;
// '301 474 4062' => (301) 474-4062
// '1111301 474 4062 ext8383' => (301) 474-4062 8383
const formatPhoneNumber = (phone) => {
    return `${phone || ''}`
        .replace(/\D+/g, '')
        .replace(/^(1|)(\d{3})(\d{3})(\d{4})(\d*)/, '$1 ($2) $3-$4 $5')
        .replace(/^\s/, '')
        .replace(/\s$/, '');
};
exports.formatPhoneNumber = formatPhoneNumber;
const getDataLayerItemVariant = (sku) => {
    if (!sku) {
        return '';
    }
    if (sku.match(/-T[13]$/) !== null) {
        return 'original';
    }
    return sku.match(/-T2$/) !== null ? 'open' : 'limited';
};
exports.getDataLayerItemVariant = getDataLayerItemVariant;
const isEmptyObject = (value) => {
    if (typeof value !== 'object' ||
        Array.isArray(value) ||
        value === null ||
        Object.keys(value).length !== 0) {
        return false;
    }
    return true;
};
exports.isEmptyObject = isEmptyObject;
const buildSchemaObjects = (index, position, category, siteUrl, slugifiedPaths, pathsCopy) => {
    // Don't add 'all' to the end of the name
    // Only add categories such as Paintings or Drawings
    const endCategory = category === 'all' ? '' : ` ${category}`;
    // Generate the id and name values,
    // define the json obj and return it
    const id = `${siteUrl}` +
        `/${category.toLowerCase()}` +
        `/${slugifiedPaths
            .slice(0, index + 1)
            .join('/')
            .toString()}`;
    const name = `${pathsCopy.slice(0, index + 1).join(' ')}${endCategory}`;
    return {
        type: 'ListItem',
        position,
        item: {
            '@id': id,
            name: name.replace('-', ' ').replace('prints', 'Art Prints'),
        },
    };
};
exports.buildSchemaObjects = buildSchemaObjects;
const updateCriticalImagePath = (subFolder, urlString) => {
    try {
        const url = new URL(urlString);
        return `${DOMAIN}/${subFolder}` + url.pathname + url.search + url.hash;
    }
    catch (error) {
        // If the URL is not absolute, return the input as it is
        return urlString;
    }
};
exports.updateCriticalImagePath = updateCriticalImagePath;
const displayEllipticalString = (string, stringLength) => {
    return string.slice(0, stringLength).concat('...');
};
exports.displayEllipticalString = displayEllipticalString;
