import { createSelector } from 'reselect'
import { User } from 'types/User'
import { UserCookies, UserPreferences, GeoLocation, GeoLocationMetaData } from 'types/User/Cookie'
import { LocaleCurrency } from 'types/Shared/Locale'
import { createStoreLocaleFromCountryCode } from 'lib/storeLocale/helpers'
import { defaultCurrencyRate, defaultCurrencyCode, defaultLocale } from 'lib/i18'
import {
  getAvailableCountries,
  getAvailableCurrencies,
  getLocaleOrder,
  getServerResponse,
} from 'selectors/shared/locale'
import { getUser } from './helpers'

export const getCookies = createSelector([getUser], (user: User): UserCookies => user.cookie || {})

export const getSaatchiVC = createSelector(
  [getCookies],
  ({ saatchivc }): UserCookies['saatchivc'] => saatchivc || ''
)

export const getAlgoliaUserToken = createSelector(
  [getSaatchiVC],
  (saatchivc): string => saatchivc || 'Anonymous'
)

const hasUserPreferences = createSelector(
  [getCookies],
  ({ userPreferences }: UserCookies): boolean => userPreferences && Object.keys(userPreferences)
)

export const getUserPreferences = createSelector(
  [hasUserPreferences, getCookies, getServerResponse],
  (hasUserPreferences, { userPreferences }, serverResponse): Partial<UserPreferences> =>
    hasUserPreferences ? userPreferences : serverResponse
)

export const getUserPreferencesConfirmed = createSelector(
  [getUserPreferences],
  ({ confirmed }): UserPreferences['confirmed'] => {
    return confirmed || false
  }
)

export const getUserPreferencesCountryCode = createSelector(
  [getUserPreferences],
  ({ countryCode }): UserPreferences['countryCode'] => {
    return countryCode || 'US'
  }
)

export const getUserPreferencesCurrency = createSelector(
  [getUserPreferences],
  ({ currency }): UserPreferences['currency'] => currency || 'USD'
)

export const getUserPreferencesCurrentCountryCode = createSelector(
  [getUserPreferences],
  ({ currentCountryCode }): UserPreferences['currentCountryCode'] => {
    return currentCountryCode || 'US'
  }
)

export const getUserPreferencesDestinationZone = createSelector(
  [getUserPreferences],
  ({ destinationZone }): UserPreferences['destinationZone'] => destinationZone || 'US'
)

export const getUserPreferencesMeasurementUnit = createSelector(
  [getUserPreferences],
  ({ measurementUnit }): UserPreferences['measurementUnit'] => measurementUnit || 'in'
)

export const getUserStoreLocale = createSelector(
  [getUserPreferences],
  ({ storeLocale }): UserPreferences['storeLocale'] => storeLocale || 'en-us'
)

// Helpers
export const getUserPreferencesCountryName = createSelector(
  [getAvailableCountries, getUserPreferencesCountryCode],
  (availableCountries, countryCode): string => {
    return availableCountries ? availableCountries[countryCode] : countryCode || countryCode
  }
)

export const getUserPreferencesStoreLocaleIsValid = createSelector(
  [getUserPreferencesCountryCode, getUserPreferencesCurrentCountryCode],
  (countryCode, currentCountryCode): boolean => {
    return countryCode === currentCountryCode
  }
)

export const getUserPreferencesLocaleConfirmed = createSelector(
  [getUserPreferencesConfirmed, getUserPreferencesStoreLocaleIsValid],
  (cookieConfirmed, storeLocaleIsValid): boolean => {
    return cookieConfirmed || storeLocaleIsValid
  }
)

export const getUserPreferencesConfirmedCountryCode = createSelector(
  [
    getUserPreferencesLocaleConfirmed,
    getUserPreferencesCountryCode,
    getUserPreferencesCurrentCountryCode,
  ],
  (confirmed, countryCode, currentCountryCode): string => {
    const countryDriver = confirmed ? countryCode : currentCountryCode
    return countryDriver
  }
)

export const getUserPreferencesConfirmedStoreLocale = createSelector(
  [getUserPreferencesLocaleConfirmed, getUserStoreLocale, getUserPreferencesCurrentCountryCode],
  (confirmed, storeLocale, currentCountryCode): string => {
    const storeLocaleDriver = confirmed
      ? storeLocale
      : createStoreLocaleFromCountryCode(currentCountryCode)
    return storeLocaleDriver
  }
)

export const getUserPreferencesConfirmedCountryName = createSelector(
  [getAvailableCountries, getUserPreferencesConfirmedCountryCode],
  (availableCountries, countryCode): string => {
    return availableCountries ? availableCountries[countryCode] : countryCode
  }
)

export const getUserPreferencesSelectedCurrency = createSelector(
  [getAvailableCurrencies, getUserPreferencesCurrency],
  (availableCurrencies, currencyCode): LocaleCurrency => {
    return availableCurrencies[currencyCode] || defaultCurrencyRate
  }
)

export const getUserPreferencesSelectedCurrencyCode = createSelector(
  [getUserPreferencesSelectedCurrency],
  (selectedLocale): LocaleCurrency['currencyCode'] =>
    selectedLocale.currencyCode || defaultCurrencyCode
)

export const getUserPreferencesSelectedCurrencyLocale = createSelector(
  [getUserPreferencesSelectedCurrency],
  (selectedLocale): LocaleCurrency['locale'] => selectedLocale.locale || defaultLocale
)

export const getUserPreferencesHasDefaultCurrency = createSelector(
  [getUserPreferencesSelectedCurrencyCode],
  (currencyCode): boolean => currencyCode === defaultCurrencyCode
)

export const getGeoLocation = createSelector(
  [
    getUserStoreLocale,
    getUserPreferencesCountryCode,
    getUserPreferencesCountryName,
    getUserPreferencesCurrency,
    getUserPreferencesDestinationZone,
    getUserPreferencesMeasurementUnit,
  ],
  (
    storeLocale,
    countryCode,
    countryName,
    currency,
    destinationZone,
    measurementUnit
  ): GeoLocation => {
    return {
      storeLocale,
      countryCode,
      countryName,
      currency,
      destinationZone,
      measurementUnit,
    }
  }
)

export const getGeoLocationMetaData = createSelector(
  [getUserStoreLocale, getLocaleOrder],
  (storeLocale, localeOrder): GeoLocationMetaData => {
    return {
      localeOrder,
      storeLocale,
    }
  }
)
