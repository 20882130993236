/*
  You can NOT currently use any component requiring REDUX STORE here because the PAGE is what contains the REDUX STORE
  So REDUX STORE is one CHILD below. Page will complain.
  What you need to do is use => `const { initialProps, initialState, store: ReduxStore } = pageProps`
  - pageProps ( contains Page information )
    - isServer: boolean
    - initialProps: Page return values ( dataLayer, metaData, etc...)
    - initialState: Flat Redux Store Object
    - store: Redux Store ( dispatch, subscribe, getState, etc... )
*/

import React from 'react'
import App from 'next/app'
import Head from 'next/head'
// import { onLCP, onFID, onCLS, onINP, onFCP, onTTFB } from 'web-vitals/attribution' // Removing `webVitalsAttribution` Feature in next.config.json
import { onLCP, onFID, onCLS, onINP, onFCP, onTTFB } from 'next/dist/compiled/web-vitals'
import { DESCRIPTION, OGIMAGE, TITLE } from 'components/MetaData/constants'
import LDJSON from 'components/MetaData/LDJSON'
import { organizationSchema, pageSchema, siteSchema } from 'components/MetaData/LDJSON/schemas'
import { createNewURL } from 'lib/storeLocale/client'
import { getGeoLocationMetaData } from 'selectors/user/cookie' // TODO - Move to Locale
import { getAvailableCountries } from 'selectors/shared/locale'
import { getPagePath } from 'selectors/page'
import { urlQuery } from 'lib/helpers'
import parse from 'lib/url/parse'
import 'sass/global.scss'
import useClearLocalStorage from 'hooks/clearLocalStorage'

const DEFAULT = `en-us`
const { APP_ENV } = process.env
const isDev = APP_ENV === 'local'
type Props = Record<string, any>

function reportMetric(metric: Record<string, any>) {
  if (isDev) {
    // The metric object ({ id, name, startTime, value, label }) is logged to the console
    // console.log('Web Vitals', { metric })
    return
  }

  /*
    In addition to the core metrics listed above, there are some additional custom metrics that measure the time it takes for the page to hydrate and render:
    Next.js-hydration: Length of time it takes for the page to start and finish hydrating (in ms)
    Next.js-route-change-to-render: Length of time it takes for a page to start rendering after a route change (in ms)
    Next.js-render: Length of time it takes for a page to finish render after a route change (in ms)
  */
  if (typeof window !== 'undefined') {
    const { id, name, value, attribution } = metric
    window.dataLayer.push({
      event: `web vitals ${name}`,
      // Google Analytics metrics must be integers, so the value is rounded.
      // For CLS the value is first multiplied by 1000 for greater precision
      // (note: increase the multiplier for greater precision if needed).
      vitals: Math.round(name === 'CLS' ? value * 1000 : value),
      // values must be integers
      // The `id` value will be unique to the current page load. When sending
      // multiple values from the same page (e.g. for CLS), Google Analytics can
      // compute a total by grouping on this ID (note: requires `eventLabel` to
      // be a dimension in your report).
      id,
      // id unique to current page load
      // CLS
      attributionElement:
        attribution?.largestShiftTarget ?? // LCP
        attribution?.element ??
        '',
    })
  }
}

// Track custom metrics
export function reportWebVitals(metric: Record<string, any>) {
  if (metric.label === 'custom') {
    // Only track custom metrics. For Web Vitals, we use the `web-vitals` package directly,
    // as it’s newer than what ships with Next.js 12.3.
    reportMetric(metric)
  }
}

// Track Web Vitals. This could be removed once we upgrade to Next.js 13
// (which includes https://github.com/vercel/next.js/pull/39368)
if (typeof window !== 'undefined') {
  onLCP(reportMetric)
  onFID(reportMetric)
  onCLS(reportMetric)
  onINP(reportMetric)
  onFCP(reportMetric)
  onTTFB(reportMetric)
}

/* We need a way to have clean path for homepage ( Product ASK )
   Ex: `.com/` => we want `.com`
   This helper will only clean up specific rule
*/
const cleanHomePage = (url: string): string => {
  return url.match(/com[/]$/) ? url.replace(/\/$/, '') : url
}

export default class MyApp extends App<Props> {
  componentDidMount(): void {
    useClearLocalStorage()
  }

  getPageTitle = (pageTitle: string) => {
    const { pageProps } = this.props
    const { initialState } = pageProps
    const geoLocationMetaData = getGeoLocationMetaData(initialState)
    const countries = getAvailableCountries(initialState)
    const { storeLocale: currentStoreLocale } = geoLocationMetaData

    const currentIso2CountryCode = (currentStoreLocale.split('-')?.[1] || 'us').toUpperCase()

    if (currentIso2CountryCode === 'US') {
      return pageTitle
    }

    const currentCountryName = countries[currentIso2CountryCode] || ''

    return currentCountryName ? `${pageTitle} ${currentCountryName}` : pageTitle
  }

  render() {
    const { Component, pageProps, err } = this.props
    const { initialProps, initialState } = pageProps
    const { metaData } = initialProps || {}
    const { description, og, product, preloadImages, robots, title, schema } = metaData || {}
    const { product: productSchema, breadcrumb: breadcrumbSchema, webpage: webpageSchema } =
      schema || {}
    const geoLocationMetaData = getGeoLocationMetaData(initialState)
    const urlPath = this.props.router?.asPath || getPagePath(initialState)
    const { storeLocale: currentStoreLocale, localeOrder } = geoLocationMetaData

    let pageTitle = this.getPageTitle(title || TITLE)
    let ogPageTitle = this.getPageTitle(og?.title || TITLE)

    // Create a storeLocale valid url w/ only PAGE as a valid query param for the canonical
    const result = parse(urlPath)
    const { origin, pathname: path, query } = result
    let queryParams = ''

    let cleanPath = path
    /* Remove trailing SLASH for SEO
      Ex: `.com/stories/` => `.com/stories`
      We don't want duplicate content
    */
    if (cleanPath.match(/.*[/]$/)) {
      cleanPath = cleanPath.replace(/\/$/, '')
    }

    if (query.page) {
      queryParams = urlQuery({
        page: query.page,
      })

      pageTitle = pageTitle.concat(` | Page ${query.page}`)
      ogPageTitle = ogPageTitle.concat(` | Page ${query.page}`)
    }

    const serverCanonicalUrl = `${origin}${cleanPath}${queryParams}` // The server does NOT send back `storeLocale` - we will need to add manually
    const canonicalUrl = cleanHomePage(createNewURL(serverCanonicalUrl, currentStoreLocale))
    const xDefaultUrl = cleanHomePage(createNewURL(serverCanonicalUrl, `en-us`))
    // Workaround for https://github.com/zeit/next.js/issues/8592
    return (
      <>
        <Head>
          {/* Meta Tags */}
          <title key='title'>{pageTitle}</title>
          <meta
            key='viewport'
            name='viewport'
            content='width=device-width, initial-scale=1.0, maximum-scale=5.0'
          />
          {description !== 'ignore' && (
            <meta name='description' key='description' content={description || DESCRIPTION} />
          )}

          {/* OG TAGS */}
          {og && (
            <>
              <meta key='og-site_name' property='og:site_name' content='Saatchi Art' />
              <meta key='og-type' property='og:type' content={og?.type || 'article'} />
              <meta key='og-image' property='og:image' content={og?.image || OGIMAGE} />
              <meta key='og-title' property='og:title' content={ogPageTitle} />
              <meta
                key='og-description'
                property='og:description'
                content={og?.description || DESCRIPTION}
              />
              <meta key='og-url' property='og:url' content={canonicalUrl} />
              <meta
                property='og:locale'
                key='og-locale'
                content={og.locale || currentStoreLocale}
              />
              <meta property='og:locale:alternate' key='og-locale-alternate' content='en_us' />

              {og.availability && (
                <meta property='og:availability' key='og-availability' content={og.availability} />
              )}
            </>
          )}

          {product?.price && (
            <>
              <meta
                property='product:price:currency'
                key='product-price-currency'
                content={product.price.currency || 'USD'}
              />
              <meta
                property='product:price:amount'
                key='product-price-amount'
                content={product.price.amount || '0.00'}
              />
            </>
          )}

          {/* No ROBOTS */}
          {!robots ? (
            <meta
              name='robots'
              key='robots'
              content='noindex, nofollow, max-image-preview:large, noai, noimageai'
            />
          ) : (
            <meta name='robots' key='robots' content='max-image-preview:large, noai, noimageai' />
          )}
          {/* Canonical */}
          <link key='canonical' rel='canonical' href={canonicalUrl} />
          {/* GEO Links */}

          <link key='xdefault' rel='alternate' href={`${xDefaultUrl}`} hrefLang='x-default' />
          {localeOrder.map((locale, index) => {
            // Do NOT add a store locale for US
            const isDefault = DEFAULT === locale
            const storeLocale = isDefault ? '' : `${locale}`
            const newURL = cleanHomePage(createNewURL(xDefaultUrl, storeLocale))
            return (
              <link
                key={`link-store-locale-${index}`}
                rel='alternate'
                href={newURL}
                hrefLang={locale}
              />
            )
          })}

          {/* Preload Images */}
          {(preloadImages || []).map((image, index) => (
            <link
              key={`preload-image-${index}`}
              rel='preload'
              fetchPriority='high'
              as='image'
              href={image}
            />
          ))}
        </Head>

        {/* JSON Schema */}
        <LDJSON data={siteSchema} />
        <LDJSON data={organizationSchema} />
        <LDJSON data={{ ...pageSchema, ...webpageSchema, url: canonicalUrl }} />
        {breadcrumbSchema && <LDJSON data={breadcrumbSchema} />}
        {productSchema && <LDJSON data={productSchema} />}

        <Component {...{ ...pageProps, err }} />
      </>
    )
  }
}
